import React from "react";
import { graphql } from "gatsby";
import { LandingRompi } from "@views/Landing";
import { LandingProps } from "src/types";

export const pageQuery = graphql`
  query LandingRompiQuery {
    # BG HERO IMAGE DESKTOP
    heroDesktop: file(relativePath: { eq: "bg/desktop/bg-polos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # BG HERO IMAGE MOBILE
    heroMobile: file(relativePath: { eq: "bg/mobile/bg-polos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 625, maxHeight: 1104, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # HERO PRODUCT IMAGE
    heroProductImg: file(relativePath: { eq: "landing/hero-rompi.png" }) {
      childImageSharp {
        fluid(maxWidth: 790, maxHeight: 1003, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # CS IMAGE
    csImg: file(relativePath: { eq: "cs.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 845, maxHeight: 366, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # BG PROJECT DESKTOP
    bgProjectDesktop: file(
      relativePath: { eq: "bg/desktop/home-chart-bg.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2024, maxHeight: 1047, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # BG PROJECT MOBILE
    bgProjectMobile: file(relativePath: { eq: "bg/mobile/home-chart-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 751, maxHeight: 1334, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # CHART IMAGE
    chart: file(relativePath: { eq: "bar-chart.png" }) {
      childImageSharp {
        fluid(maxWidth: 725, maxHeight: 442, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # PRODUCT IMAGE
    productImg: file(relativePath: { eq: "landing/rompi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 658, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # SERVICES ICON
    services: allFile(
      filter: {
        dir: { regex: "/(icon)/(services)/" }
        name: { regex: "/(icon)/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }

    # CLIENT LOGO
    clientLogo: allFile(
      filter: { dir: { regex: "/(klien-kami)/" }, name: { regex: "/()/" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          id
          base
          childImageSharp {
            fluid(maxHeight: 100, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default function Index(props: LandingProps) {
  return <LandingRompi {...props} referBtnPorto="/portofolio/rompi" />;
}
